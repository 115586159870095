<template>
  <div v-if="type === 'option'">
    <template
        v-if="!!default_option_list && default_option_list.length > 0"
    >
      <div
          v-for="(xitem) in default_option_list"
          :key="xitem.id"
      >
        <div class="option_title" style="margin-bottom: 12px">

          <my-select-alt
              :title="getOptName(xitem)"

              :items="xitem.details"
              v-model="xitem.selected"
              :mandatory="xitem.type === 'MANDATORY'"
              :max_selection="xitem.max_selection"
              :min_selection="xitem.min_selection"
              :warning="true"
              @change="changeOption"
          />
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import MyInput from '@/components/form/MyInput'
import MySelect from '@/components/form/MySelect'
import MySelectSo from '@/components/form/MySelectSo'
import MySelectAlt from '@/components/form/MySelectAlt'

export default {
  computed: mapGetters({
    admin_pos: 'admin_pos',
    shop_uid: 'shop_uid',
  }),
  props: {
    type: {
      type: String,
      default: 'option',
    },
    _default_option_list: null,
  },
  components: {
    MyInput,
    MySelect,
    MySelectAlt,
    MySelectSo,
  },
  mounted() {
    this.init_items()
  },
  watch: {
    _default_option_list: function () {
      this.init_items()
    },
  },
  data: () => ({
    default_option_list: null,
    options: null,
  }),
  methods: {
    getOptName:function (xitem) {
      let msg = xitem.alt_name ? xitem.alt_name:xitem.name

      if(xitem.type === 'MANDATORY') {

        msg += ' ' + this.$t('mandatory_option')

        return msg
      } else {
        return msg
      }
        //? `${xitem.name} ${$t('mandatory_option')}` : xitem.name
    },
    init_items: function () {
      this.default_option_list = this._default_option_list
      this.default_option_list.forEach(el => {
        el.selected = null
      })
    },
    changeOption: function () {
      var value = []
      this.default_option_list.forEach(el => {
        if (el.selected && el.selected.length > 0) {
          var o = {
            id: el.id,
            value: el.selected,
          }
          value.push(o)
        }
      })
      this.$emit('changeOption', value)
    },
  },
}
</script>
