<template>
  <!--notice//-->
  <div
    v-if="items.length>0"
    style="height:40px;min-height:40px;text-align:center;font-weight: 400;font-size: 1.2em;position: relative">
    <swiper-container
        style="height: 100%"
        :slides-per-view="1"
        :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }"

        :pagination="false"
    >
      <swiper-slide
          style="height: 100%"
          v-for="(item, i) in items"
          :key="i"
      >
        <div
            @click="go_notice(item)"
            :style="'height:100%;align-items: center;display: flex;text-align:left;cursor: pointer;background-color:'+color+';color:#fff'">
          <div style="min-width: 52px">
                <span class="icon">
                              공지
                          </span>
          </div>
          <div style="font-size:0.8em;flex-grow:1;color:#ffffff;">
                <span v-line-clamp:20="1" class="white">
                {{item.title}}
                </span>
          </div>
        </div>
      </swiper-slide>

    </swiper-container>
  </div>
</template>
<script>
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();


import { mapMutations, mapGetters } from 'vuex'
export default {
  props:{
  },
  created() {
  },
  mounted() {

    //this.color = "#ff1b81"
    this.color = '#333'
    this.load()
  },
  watch:{
  },
  computed:{
    ...mapGetters({
      shop_info:'shop_info',
    }),
  },
  components: {
  },
  data: () => ({
    color:'#000',
    isie:false,
    contents:[],
    items:[]
  }),
  methods: {
    check_msg:function () {
        // 모두 제거 되었다면, 다시 공지 노출
        this.items = []
        this.contents.forEach(el=>{
          this.items.push(el)
        })

      this.$nextTick(() => {
        this.$emit('load')
      })
    },
    go_notice:function(item) {
      this.emitter.emit("confirm_popup",
          {
            notice:true,
            value:item.id
          }
      )
    },
    load:function () {
      var url = "/api/myshp/get/notice/weight?shopid="+this.shop_info.shopid
      this.$http.get(url).then(data => {
        this.contents = data.data
        this.check_msg()

        this.$nextTick(() => {
          this.$emit('load')
        })
      })
    }
  }
}
</script>
<style scoped>
.icon {

  border-radius: 4px;
  margin: 0 9px 0 9px;
  box-sizing: border-box;
  font-size: 12px;
  color: #f53535;
  font-weight: 700;
  text-align: center;
  padding: 4px;

  border: 0;
  color: #f53535;
  background-color: #fdf3f3;
  line-height: 1.1;
  letter-spacing: -1px;

}
.btn_go {display:inline-block;;width:auto;height:100%;margin:0 0 0 10px;padding:6px 10px 4px;line-height:1.2;border-radius:25px;color:#333;font-size:11px;word-break:break-all;text-align:center;background:#f91d65;}
</style>
