<template>
  <div class="number_border" :style="!height?'font-size:18px':'height:'+height+'px;font-size:16px'">
    <div
        @touchend="inc(0, -1)"
        @click="inc(1, -1)"
         :style="'align-self: center;font-size:'+(default_font_size*1.1)+'em;'"
         class="btn_minus">-</div>
    <div style="flex:1;display: flex;justify-content: center;min-width: 83px;">
      <div :style="'font-size:'+(default_font_size*1.1)+'em;align-self: center;color: black'" v-text="my_value"/>
      <input type="text" v-model="my_value"
             v-if="false"
      @keydown="keydown"
      class="i2" :style="'align-self: center;border: 0;text-align: center;font-size:'+(default_font_size*1.1)+'em;font-weight: bold;flex-grow: 1;'"
      />
    </div>
    <div
        @touchend="inc(0, 1)"
        @click="inc(1, 1)"
         :style="'align-self: center;font-size:'+(default_font_size*1.1)+'em;'"
         class="btn_plus">+</div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props:{
    value:null,
    font_size:{
      default:'16px',
      type:String
    },
    height:{
      default:40,
      type:Number
    }
  },
  components: {},
  mounted() {
    this.my_value = this.value
    if(!this.my_value)
      this.my_value = 1

    this.$nextTick(() => {
      this.inited = true
    })
  },
  watch:{
    value:function() {
      this.my_value = this.value
    },
    my_value:function () {

      try {
        var v = parseInt(this.my_value)
        this.my_value = v
        if(this.my_value.toString().indexOf("N")==0) {
          this.my_value = '0'  
        }
      } catch(e) {
        this.my_value = '0'
      }

      if(!this.inited)
        return

      this.$emit('update:modelValue', this.my_value)
      this.$emit('change', this.my_value)
    },
  },
  computed: mapGetters({
    default_font_size:'default_font_size',
    shop_uid:'shop_uid'
  }),
  data: () => ({
    my_value:null,
    inited:false,
  }),
  methods:{
    keydown:function(event) {
      var i = event.key.toString()
      if(i.toLowerCase() == 'backspace' || i.toLowerCase() == 'delete' || i.toLowerCase() == 'arrowleft' || i.toLowerCase() == 'arrowright') {

      }
      else if("0123456789".indexOf(i)<0) {
        event.preventDefault()
        event.stopPropagation()
      }
    },
    inc:function (ty, v) {
      if(this.shop_uid && ty==0) {
        return
      }
      else if(!this.shop_uid && ty==1) {
        return
      }

      var x = parseInt(this.my_value) + v
      if(x<=0)
        x = 1
      this.my_value = x
    }
  }
}
</script>
<style scoped>
.i2 {
  width: 100%;
  height: 100%;
  line-height: 1;
  margin:0;
  padding:0;
}
.i2:focus {
outline: 0 !important;
text-decoration: underline;
box-shadow: none !important;
}
.number_border{font-size:0;
  
  display: flex;}
.number_border .btn_plus
{
  border-radius: 10px;
  cursor: pointer;
  display:inline-block;
  padding:10px 15px;
  background-color: #bbb;color: #fff;border: 0;
  text-align: center;
  font-weight: bold;
}
.btn_plus:active {
  transform: translateY(2px);
}

.number_border .btn_minus{
  border-radius: 10px;
  cursor: pointer;
  display:inline-block;
  padding:10px 15px;
  background-color: #bbb;color: #fff;border: 0;
  font-weight: bold;
  text-align: center;
}
.btn_minus:active {
  transform: translateY(2px);
}

.number span{
  align-items: center;
  justify-content: center;
  display:flex;height:100%;flex-grow:1;padding:0 5px;font-size:12px;color:#212121;text-align:center;border-top:1px solid #ececec;border-bottom:1px solid #ececec;}

.number_border {
  /*
  border: 2px #bbb solid;
  background-color: #fff;
  */
  color: #fff;
  font-weight: bold;
  /*
  padding: 8px;
  border-radius: 15px;
   */
}
</style>
