const methods = {
    app_event(cmd, data){
        try {
            if(window.FlutterWebView) {
                var message = {
                    command: cmd,
                    data: data
                }
                var msg = JSON.stringify(message)
                window.FlutterWebView.postMessage(msg)
            }else if(window.MyshopKioskWebView) {
                var message = {
                    command: cmd,
                    data: data
                }
                var msg = JSON.stringify(message)
                window.MyshopKioskWebView.getWebMessage(msg)
            }
        } catch (ex) {
            console.log(ex)
        }
    },
    pos_app_event(cmd, data){
        try {
            if(window.MyshopPosWebView) {
                var message = {
                    command: cmd,
                    data: data
                }
                var msg = JSON.stringify(message)
                window.MyshopPosWebView.getWebMessage(msg)
            }
        } catch (ex) {
            console.log(ex)
        }
    }
}

export default {
    install(Vue) {
        Vue.config.globalProperties.$app_event = methods.app_event;
        Vue.config.globalProperties.$pos_app_event = methods.pos_app_event;
    }
}