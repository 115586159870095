<template>
  <div v-if="show" class="dialog">
    <div class="rounded_card" style="width:80%;max-width:600px">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 20px;">
        <div class="dialog_content" v-html="message" :style="'font-size:'+(default_font_size*1.2)+'em'">
        </div>
        <div v-if="type == 'receipt_yn'" :style="'font-size:'+(default_font_size*1.0)+'em;color:#4d3750;'">
          {{receipt_count}}초 후 닫힙니다.
        </div>
        <div class="d-flex" style="width: 100%;padding-top:40px;padding-right: 20px;padding-left: 20px;">
          <div
              :style="'font-size:'+(default_font_size*1.2)+'em'"
              v-if="show_cancel" class="btn_cancel"
              @touchend="cancelBefore(0)"
              @click="cancelBefore(1)">
            {{ cancel_text }}
          </div>
          <div style="width:15px"/>
          <div
              :style="'font-size:'+(default_font_size*1.2)+'em'"
              class="btn_next"
              @touchend="nextBefore(0)"
              @click="nextBefore(1)">
            {{ next_text }}
          </div>
        </div>
        <div class="d-flex" style="width: 100%;padding-top:20px;padding-right: 20px;padding-left: 20px;">
          <div
              :style="'font-size:'+(default_font_size*1.2)+'em'"
              v-if="type == 'notice_cash_receipts'" class="btn_home"
              @touchend="goHome(0)"
              @click="goHome(1)">
            처음으로
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CustomDialog",
  computed: mapGetters({
    default_font_size:'default_font_size',
    pay_type: 'pay_type',
    cart_items: 'cart_items',
    pay_amount: 'pay_amount',
    point_num: 'point_num',
    receipts_type: 'receipts_type',
    shop_uid: 'shop_uid',
  }),
  watch:{
    point_num: {
      handler() {
      },
    },
    receipts_type: {
      handler() {
      },
    },
    pay_amount: {
      handler() {

      },
    }
  },
  data: () => ({
    type: '',
    show: false,
    show_cancel: false,
    message: '',
    cancel_text: '',
    next_text: '',
    json_data: {},
    timer : null,
    receipt_count: 15,
    receipt_interval: null,
  }),
  mounted() {
    // 이벤트 등록
    this.emitter.on('not_found', this.notFound)
    this.emitter.on('not_for_sale_time', this.notForSaleTime)
    this.emitter.on('not_for_sale', this.notForSale)
    this.emitter.on('stop_temporally', this.stopTemporally)
    this.emitter.on('notice_point', this.noticePoint)
    this.emitter.on('notice_cash_receipts', this.noticeCashReceipts)
    this.emitter.on('select_cash_receipts', this.selectCashReceipts)
    this.emitter.on('receipt_yn', this.receiptYn)
    this.emitter.on('alert_msg', this.alertMsg)
    this.emitter.on('card_alert_msg', this.cardAlertMsg)
    this.emitter.on('point_alert_msg', this.pointAlertMsg)
  },
  methods: {
    cancelBefore(ty) {
      if(this.shop_uid && ty==0) {
        return
      }
      else if(!this.shop_uid && ty==1) {
        return
      }

      this.cancelProcess()
    },
    nextBefore(ty) {
      if(this.shop_uid && ty==0) {
        return
      }
      else if(!this.shop_uid && ty==1) {
        return
      }

      var that = this
      setTimeout(function () {
        that.nextProcess()
      },200)
    },
    goHome(ty) {
      if(this.shop_uid && ty==0) {
        return
      }
      else if(!this.shop_uid && ty==1) {
        return
      }

      var that = this
      setTimeout(function () {
        that.$app_event("audio", "order_cancel")
        location.href = "index.html"
      },200)
    },
    cancelProcess() {
      this.show=false
      if(this.timer != null) {
        clearTimeout(this.timer)
        this.timer = null
      }
      if(this.receipt_interval != null){
        clearInterval(this.receipt_interval)
        this.receipt_interval = null
      }
      if(this.type == 'notice_point') {
        this.$store.commit('point_num',{value:null})
        this.$store.commit('current_point_amount',{value:0})
        if(this.pay_type == 'pay_card') {
          this.emitter.emit("payment_card_without_point")
        }else if(this.pay_type == 'pay_zero') {
          this.emitter.emit("payment_zeropay_without_point")
        }else if(this.pay_type == 'pay_barcode') {
          this.emitter.emit("payment_barcode_without_point")
        }else if(this.pay_type == 'pay_simple') {
          this.emitter.emit("payment_simple_without_point")
        }else if(this.pay_type == 'pay_seoul') {
          this.emitter.emit("payment_seoulpay_without_point")
        }else if(this.pay_type == 'pay_cash') {
          this.emitter.emit("payment_cash_without_point")
        }else if(this.pay_type == 'pay_cash_receipts') {
          this.emitter.emit("payment_cash_receipts_without_point")
        }
      } else if(this.type == 'notice_cash_receipts') {
        this.$store.commit('receipts_type',{value:null})
        this.$store.commit('pay_type',{value:'pay_cash'})
        let earnPoint = this.pay_amount.total_earn_point
        if(window.myshop_info.point == true && earnPoint > 0) {
          this.emitter.emit("notice_point")
        } else {
          this.emitter.emit("payment_cash_without_point")
        }
        //this.emitter.emit("payment_card_without_point")
      } else if(this.type == 'select_cash_receipts') {
        this.$store.commit('receipts_type',{value:'P'})
        this.$store.commit('pay_type',{value:'pay_cash_receipts'})
        let earnPoint = this.pay_amount.total_earn_point
        if(window.myshop_info.point == true && earnPoint > 0) {
          this.emitter.emit("notice_point")
        } else {
          this.emitter.emit("payment_cash_receipts_without_point")
        }
      } else if(this.type == 'receipt_yn') {
        //this.$app_event("audio", "stop")
        if(typeof window.display_info.receipt == 'undefined' || window.display_info.receipt !== 'mobile') {
          if(this.json_data.order_info.need_manage == true) {
            this.$app_event("print_orderno", this.json_data)
          }
        }
        this.emitter.emit("payment_complete")
        this.json_data = {}
      }
    },
    nextProcess() {
      this.show=false
      if(this.timer != null) {
        clearTimeout(this.timer)
        this.timer = null
      }
      if(this.receipt_interval != null){
        clearInterval(this.receipt_interval)
        this.receipt_interval = null
      }
      if(this.type == 'not_found' || this.type == 'not_for_sale_time'
          || this.type == 'not_for_sale' || this.type == 'alert_msg' || this.type == 'point_alert_msg') {
      } else if(this.type == 'card_alert_msg'){
        this.$store.commit('disable_scan', {value:true})
        if(this.point_num == null) {
          this.emitter.emit("payment_card_without_point")
        }else {
          this.emitter.emit("payment_card_with_point")
        }
      } else if(this.type == 'notice_point') {
        var jdata = {}
        jdata.num_type = 'point'
        this.emitter.emit('enter_phone_num', jdata)
        this.$app_event("audio", "enter_number")
        //this.$app_event('pay_card')
      } else if(this.type == 'notice_cash_receipts') {
        this.$store.commit('pay_type',{value:'pay_cash_receipts'})
        this.emitter.emit("select_cash_receipts")
      } else if(this.type == 'select_cash_receipts') {
        this.$store.commit('receipts_type',{value:'C'})
        this.$store.commit('pay_type',{value:'pay_cash_receipts'})
        let earnPoint = this.pay_amount.total_earn_point
        if(window.myshop_info.point == true && earnPoint > 0) {
          this.emitter.emit("notice_point")
        } else {
          this.emitter.emit("payment_cash_receipts_without_point")
        }
      } else if(this.type == 'receipt_yn') {
        if(typeof window.display_info.receipt !== 'undefined' && window.display_info.receipt == 'mobile') {
          var jdata1 = {}
          jdata1.num_type = 'receipt'
          jdata1.data = this.json_data
          //jdata1.phone = this.json_data
          this.emitter.emit('enter_phone_num', jdata1)
        } else {
          this.$app_event("print_receipt", this.json_data)
          this.emitter.emit("payment_complete")
          this.json_data = {}
        }
      }
    },
    autoClose() {
      this.show = false
    },
    notFound() {
      this.type = 'not_found'
      this.show_cancel = false
      this.message = this.$t('dialog.not_found')
      this.next_text = this.$t('close')
      this.timer = setTimeout(this.autoClose, 5000)
      this.show = true
    },
    notForSaleTime() {
      this.type = 'not_for_sale_time'
      this.show_cancel = false
      this.message = this.$t('dialog.not_for_sale_time')
      this.next_text = this.$t('close')
      this.timer = setTimeout(this.autoClose, 5000)
      this.show = true
    },
    notForSale() {
      this.type = 'not_for_sale'
      this.show_cancel = false
      this.message = this.$t('dialog.not_for_sale')
      this.next_text = this.$t('close')
      this.timer = setTimeout(this.autoClose, 5000)
      this.show = true
    },
    stopTemporally() {
      this.type = 'stop_temporally'
      this.show_cancel = false
      this.message = this.$t('dialog.stop_temporally')
      this.next_text = this.$t('close')
      this.timer = setTimeout(this.autoClose, 5000)
      this.show = true
    },
    alertMsg(data) {
      this.emitter.emit("payment_cancel")
      this.type = 'alert_msg'
      this.show_cancel = false
      this.message = '<div style="font-size:' + (this.default_font_size*1.0) + 'em">' + data.alert_msg + '</div>'
      this.next_text = this.$t('confirm')
      this.timer = setTimeout(this.autoClose, 5000)
      this.show = true
    },
    cardAlertMsg(data) {
      this.emitter.emit("payment_cancel")
      this.type = 'card_alert_msg'
      this.show_cancel = true
      this.message = '<div><span style="font-size:' + (this.default_font_size*1.0) + 'em">오류 : ' + data.card_alert_msg + '</span><br><br><span style="font-size:' + (this.default_font_size*0.9) + 'em">동일한 결제방식으로<br>재시도 하시겠습니까?</span></div>'
      this.cancel_text = this.$t('cancel')
      this.next_text = '다시 결제'
      this.timer = setTimeout(this.autoClose, 10000)
      this.show = true
    },
    pointAlertMsg(data) {
      this.emitter.emit("payment_cancel")
      this.type = 'point_alert_msg'
      this.show_cancel = false
      this.message = '<div><span style="font-size:' + (this.default_font_size*1.0) + 'em">오류 : ' + data.point_alert_msg + '</span><br><br><span style="font-size:' + (this.default_font_size*0.8) + 'em">결제를 취소하시거나, <br>포인트 결제를 재시도 하시면<br>차감된 포인트가 원상복구됩니다.</span></div>'
      this.next_text = this.$t('confirm')
      this.timer = setTimeout(this.autoClose, 5000)
      this.show = true
    },
    noticePoint() {
      //var pr = window.myshop_info.earn_point_rate
      //var exPoint = parseInt(this.pay_amount.total_price * pr / 100)
      this.type = 'notice_point'
      this.show_cancel = true
      this.message = '<b>' + this.pay_amount.total_earn_point + this.$t('dialog.need_point')
      this.cancel_text = this.$t('dialog.no_point')
      this.next_text = this.$t('dialog.ok_point')
      this.show = true
      this.$app_event("audio", "need_point")
    },
    noticeCashReceipts() {
      this.type = 'notice_cash_receipts'
      this.show_cancel = true
      this.message = '현금 영수증을 발급하시겠습니까?'
      this.cancel_text = '발급안함'
      this.next_text = '발급하기'
      this.show = true
    },
    selectCashReceipts() {
      this.type = 'select_cash_receipts'
      this.show_cancel = true
      this.message = '발급 대상을 선택해주세요.'
      this.cancel_text = '개인'
      this.next_text = '사업자'
      this.show = true
    },
    receiptYn(data) {
      var that = this
      this.json_data = data.pay_data
      this.type = 'receipt_yn'
      this.show_cancel = true

      if(typeof window.display_info.receipt !== 'undefined' && window.display_info.receipt == 'mobile') {
        if(this.json_data.order_info.need_manage == true) {
          this.message = this.$t('dialog.mobile_receipt_all')
          //this.$app_event("audio", "receipt_mobile")
        } else {
          this.message = this.$t('dialog.mobile_receipt')
          //this.$app_event("audio", "receipt_mobile")
        }
        this.cancel_text = this.$t('dialog.no')
        this.next_text = this.$t('dialog.yes')
      } else {
        this.message = this.$t('dialog.receipt_yn')
        this.cancel_text = this.$t('dialog.no')
        this.next_text = this.$t('dialog.yes')
        this.$app_event("audio", "receipt")
      }

      this.show = true
      this.receipt_interval = setInterval(function () {
        if(that.receipt_count <= 1) {
          that.cancelProcess()
        } else --that.receipt_count
      }, 1000)
    }
  }
}
</script>

<style scoped>
</style>