<template>
  <div class="field" style="padding: 0 10px;display: flex;flex-direction: column">
    <div style="font-size: 1.15em;padding-bottom: 15px;">{{label}}</div>
    <div style="display: flex">
      <input style="flex: 1" type="text" @keydown.enter="on_enter" v-model="my_value">
    </div>
  </div>
</template>
<script>

export default {
  props: {
    label:{
      type:String,default:''
    },
    modelValue: Object
  },
  data: () => ({
    my_value: null,
  }),
  components: {},
  mounted() {
    if (this.modelValue) {
      this.my_value = this.modelValue
    }
  },
  watch:{
    modelValue:function () {
      this.my_value = this.modelValue
    },
    my_value: function () {
      this.$emit('update:modelValue', this.my_value)
      this.$emit('change', this.my_value)
    }
  },
  methods:{
    on_enter:function () {
      this.$emit('enter')
    }
  }
}
</script>
<style scoped>
.field input {
  font-size: 16px;
  line-height: 22px;
  padding: 4px 8px;
  width: 100%;
  min-height: 44px;
  border: 1px solid #999;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  /*
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px;
   */
}
</style>