import ko from './ko.json'
import en from './en.json'
import ja from './ja.json'
import fr from './fr.json'
import th from './th.json'
import zh from './zh.json'
import de from './de.json'
import vi from './vi.json'
import es from './es.json'

export default {
    'ko': ko,
    'es': es,
    'en': en,
    'ja': ja,
    'fr': fr,
    'th': th,
    'zh': zh,
    'vi': vi,
    'de': de,
}
