<template>
  <div v-if="!multi">
    <div style="position: relative">
      <input
        type="text"
        class="minput2"
        v-model="my_value"
        :disabled="disabled"
      />
      <div v-if="mandatory" :class="getStatus"></div>
      <div v-if="getPlaceholderStatus" class="placeholder">
        -- 입력해주세요 --
      </div>
    </div>
  </div>
  <div v-else>
    <div style="position: relative">
      <textarea
        class="minput2"
        v-model="my_value"
        :disabled="disabled"
        :style="!!height?'height:'+height+'px':''"
      />
      <div v-if="mandatory" :class="getStatus"></div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    min_length:{
      type:Number,
      default:null
    },
    height:{
      type:Number,
      default:null
    },
    multi:{
      type:Boolean,
      default:false
    },
    mandatory:{
      type:Boolean,
      default:true
    },
    disabled:{
      type:Boolean,
      default:false
    },
    value:null,
  },
  components: {},
  mounted() {
    this.my_value = this.value
  },
  watch:{
    value:function() {
      this.my_value = this.value
    },
    my_value:function () {
      this.$emit('input', this.my_value)
      this.$emit('change', this.my_value)
    },
  },
  computed:{
    getPlaceholderStatus:function () {
      if(this.isValid()==0)
        return true
      else if(this.isValid()==-1) {
        if(this.my_value!=undefined&&this.my_value!=null&&this.my_value!='')
          return false

        return true
      }
      else
        return false
    },
    getStatus:function () {

      if(this.isValid()==-1)
        return 'status2 disabled'
      if(this.isValid()==1)
        return 'status2 on'
      if(this.isValid()==0)
        return 'status2'
    }
  },
  data: () => ({
    my_value:null
  }),
  methods:{
    isValid:function () {

      var stat = 0
      if(this.disabled) {
        return -1
      } else if(this.my_value!=undefined&&this.my_value!=null&&this.my_value!='') {
        if(!!this.min_length) {
          if(this.my_value.length>=this.min_length)
            return 1
          else
            return 0
        }
        return 1
      }
      return stat
    }
  }
}
</script>
<style scoped>
.minput2:disabled {
  background-color: #f0f0f099;
}
.minput2{
  min-width: 100px;
  display:block;width:100%;height:44px;margin:0 0 8px;padding:10px 16px;font-size:12px;color:#212121;letter-spacing:-1px;border:1px solid #bababa;font-family:inherit;border-radius:3px;-webkit-appearance:none;-moz-appearance:none;appearance:none;}
.status2 {
  margin: -2px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: rgb(252, 196, 23);
  position: absolute;
  left: 0;
  top: 0;
}
.status2.on {
  background-color: rgb(80, 164, 125);
}
.status2.disabled {
  background-color: #ddd;
}
.placeholder {
  position: absolute;
  top:0;
  left: 0;
  padding-top: 15px;
  width: 100%;
  text-align: center;
  z-index: -1;
}
</style>
