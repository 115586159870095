<template>
  <div v-if="show" class="dialog">
    <div
        v-aspect-ratio="'1:1'"
        style="overflow: hidden;position: relative;width: 80vw;padding-bottom:80% !important;"
    >
      <div class="bg"
          :style="'width: 80vw;height: 80vw;background-image: url('+require('@/assets/images/close.png')+')'"
      >
      </div>
    </div>
  </div>
</template>

<script>
import { directive } from 'v-aspect-ratio'

export default {
  name: "CloseDialog",
  directives: {
    'aspect-ratio': directive,
  },
  mounted() {
    // 이벤트 등록
    this.emitter.on('close', this.close)
  },
  data: () => ({
    show: false,
  }),
  methods: {
    close(bClose) {
      if(bClose) {
        this.show = true
      } else {
        this.show = false
      }
    }
  }
}
</script>

<style scoped>
.bg {
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
</style>