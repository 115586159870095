import {mapGetters} from 'vuex'

var myshopDetailView = {

  computed: {
    ...mapGetters({
      member_sale:'member_sale',
      maininfo: 'maininfo',
      myshop_host: 'myshop_host',
      selected_product: 'selected_product',
      lang_current_code:'lang_current_code',
    }),
  },
  mounted() {
    try {
      if (window.screen.width < 330)
        this.smallDevice = true
      else
        this.smallDevice = false
    } catch (e) {
    }

    this.onResize()
  },
  watch: {
    '$route.params.id': function () {
      this.load()
    },
    selOption: function () {
      // 옵션이 변경되었다
      //console.log(this.selOption)
      //this.changeOption()

      // 추가된 가격을 계산해서 업뎃한다
      let add_cost = 0
      let opt_name = ''
      let opt_name_alt = ''
      this.default_option_list.forEach(el=>{
        var id = el.id
        var details = el.details

        this.selOption.forEach(el2=>{

          if(el2.id == id) {
            // 선택된 값이다
            let value = el2.value
            details.forEach(el3=>{
              value.forEach(el4=>{
                if(el3.id == el4) {
                  add_cost += el3.price

                  //
                  let opt = ''
                  opt += el.name + ' : '
                  opt += el3.name


                  let opt2 = ''
                  if(el.alt_name)
                    opt2 += el.alt_name + ' : '
                  else
                    opt2 += el.name + ' : '

                  if(el3.alt_name)
                    opt2 += el3.alt_name
                  else
                    opt2 += el3.name


                  if(opt_name != '') {
                    opt_name += ', '
                  }
                  opt_name += opt


                  if(opt_name_alt != '') {
                    opt_name_alt += ', '
                  }
                  opt_name_alt += opt2
                }
              })
            })
          }
        })
      })

      //console.log(add_cost)
      //add_cost
      //console.log(this.selected_product)

      var o = JSON.parse(JSON.stringify(this.selected_product))
      o.add_cost = add_cost
      o.selected_option = JSON.parse(JSON.stringify(this.selOption))
      o.opt_name = opt_name
      o.opt_name_alt = opt_name_alt

      //opt_name

      this.$store.commit('selected_product', {value:o})
    },
  },

  created() {
  },
  beforeDestroy() {
  },
  components: {
  },
  data: () => ({
    buying: false,
    //pdata: null,
    options: null,
    default_option_list:null,
    
    smallDevice: false,

    init: false,

    tab: 'tab-1',
    tab_list: [],

    detail_share: false,

    sale: null,
    point: null,
    curtab: 0,

    mobile: false,
    minw: null,

    preview2_css: '',
    preview: false,
    imgsrc: null,

    images: null,

    price: null,
    org_price: null,

    available: true,
    descBadge: {},

    comments: null,
    //maininfo:null,
    priceInfo: null,

    maxContent: '',
    contents: null,
    contents_refund: null,

    selOption: null,
    optionName: null,

    option_mapper: [],
    options: [],

    limit_same: false,
    grp_options: [],
    grp_options_sel: [],

    cssinnner: '',
    ratio: 1,
    opt: [
      {
        id: 1,
        name: '새옵션1',
        orig_price: 40000,
        price: 34000
      },

    ],
    item: {
      price: 21000,
      name: '가나다라 원피스',
      images: [
        "/sample/s1.jpg",
      ]
    }
  }),
  methods: {
    changeOption_layer:function (v) {
      // 옵션이 선택되었다.
      this.selOption = v
    },
    initLoad: function () {
      //console.log('initload')
      if (this.maininfo) {
        this.load()
      }
    },
    load: function () {
      // 컨텐츠 업데이트
      // this.redrawContents()

      // reset
      this.options = null
      this.default_option_list = null
      this.$store.commit('product_loaded', {value: false})



      if (!!this.maininfo.hot) {
        this.descBadge["hot"] = true
      }
      if (!!this.maininfo.xnew) {
        this.descBadge["new"] = true
      }

      this.load3()

      /*
      // 그룹상품 사용안함
      if (!!this.maininfo.grp) {
        this.loadGrpProduct()
      }
      */
    },
    get_default_option: function() {
      return this.default_option_list
    },
    load_options: function (callback) {
      if(this.shop_uid) {
        let surl = '/core-api/prod/get/products/options/'+this.lang_current_code
        surl += '?pid=' + this.maininfo.id

        this.$http.get(surl)
            .then(res => {
              this.options = res.data

              this.default_option_list = this.options

              // sale 확인
              if(this.member_sale) {
                this.default_option_list.forEach(el=>{
                  let details = el.details
                  if(details) {
                    details.forEach(el2=>{
                      el2.price = el2.price - (el2.price*this.member_sale/100)
                    })
                  }
                })
              }


              this.$store.commit('product_loaded', {value: true})
            }).catch(err => {
              // 그냥 옵션이 없는 상품이라 생각하자.
              this.$store.commit('product_loaded', {value: true})
        })
      } else {
        if (this.option_mappings != null && this.option_mappings[this.maininfo.id]) {
          var mappingOptions = this.option_mappings[this.maininfo.id].options
          //console.log(mappingOptions)
          var parsedOptions = []
          for (let op of mappingOptions) {
            if(this.prod_options != null && this.prod_options[op]) {
              parsedOptions.push(this.prod_options[op])
            }
          }
          this.options = parsedOptions
          this.default_option_list = this.options
          //console.log(this.options)
          this.$store.commit('product_loaded', {value: true})
        } else {
          this.$store.commit('product_loaded', {value: true})
        }
      }
    },
    load3: function () {
      //this.pdata = {pid: this.maininfo.id, pid2: this.maininfo.pid2, price: this.maininfo.price, qty: this.maininfo.qty}
      this.load_options()
    },
    changeOption: function () {
      // 선택옵션?
      var o = null

      this.option_mapper.forEach(el => {
        if (this.selOption == el.pid2) {
          o = el
        }
      })

      // 옵션을 선택한 상태임
      if (!!!o || o.price <= 0) {
        this.available = false
        this.org_price = o.org_price
        this.price = o.price
        this.sale = null
        this.optionName = o.name
        return
      }

      this.available = true
      this.org_price = o.org_price
      this.price = o.price
      this.sale = null
      this.optionName = o.name

      if (!!this.org_price) {
        var p = this.price / this.org_price * 100
        p = parseInt(p)
        p = 100 - p

        // sale
        this.sale = p
      }
    },
    onResize: function () {

      var mx = 900

      //var o = this.$refs.main
      /*
      if(!!o) {
          var w = o.clientWidth
      }
      */

      var w2 = window.innerWidth
      if (mx < w2) {
        w2 = mx
      }
    },
    checkLimitSaleVisible:function (item) {
      return item['sales_now']
    },
    checkLimitSaleMsg:function (item) {

      // 판매대기중 (중단)
      if(item['sales_now']==0) {

        if(item['remained_to_start_sec']<=0) {
          return '<div style="text-align:center;"> <i class="fal fa-alarm-clock" style="padding-right: 2px"> <span style="font-size:14px;font-weight: bold">판매가 종료되었습니다.</span></div>'
        }

        var remained_to_start_sec = item['remained_to_start_sec'];
        //var stop_sales_sec = item['stop_sales_sec'];

        var ts = new Date().getTime()+remained_to_start_sec*1000
        var v = this.$moment(ts).format('YYYY.MM.DD HH:mm').toString()

        // 오늘과 시간차이
        {
          var now = this.$moment(new Date()).format('YYYY.MM.DD').toString()
          var d2 = this.$moment(ts).format('YYYY.MM.DD').toString()

          var _d1 = new Date(now).getTime()
          var _d2 = new Date(d2).getTime()

          var rem = _d2-_d1
          var day = 1000*60*60*24
          var dday = parseInt(rem / day)
        }

        var a = '<i class="fal fa-cash-register" style="padding-right: 3px;font-size: 24px"></i>\n' +
          '                D-'+dday+'\n<div style="padding-top: 5px;font-size: 13px">판매시작</div>' +
          '                <div style="padding-top: 5px;font-size: 12px">\n' +
          v +
          '                </div>\n'
        return a

      } else if(item['sales_now']==1) {


        var stop_sales_sec = item['stop_sales_sec'];
        //var stop_sales_sec = item['stop_sales_sec'];

        var ts = new Date().getTime()+stop_sales_sec*1000
        var v = this.$moment(ts).format('HH:mm').toString()

        // 오늘과 시간차이
        {
          var now = this.$moment(new Date()).format('YYYY.MM.DD').toString()
          var d2 = this.$moment(ts).format('YYYY.MM.DD').toString()

          var _d1 = new Date(now).getTime()
          var _d2 = new Date(d2).getTime()

          var rem = _d2-_d1
          var day = 1000*60*60*24
          var dday = parseInt(rem / day)
        }

        var dtxt = ''
        if(dday == 0)
          dtxt = '오늘'
        else if(dday == 1)
          dtxt = '내일'
        else
          dtxt = dday+'일 후'
        var res = '<i class="fal fa-alarm-clock" style="font-size:11pxpadding-right: 2px"></i>\n' +
          dtxt+' '+v+'<br/>까지 판매중'
        return res
      }
    },
  }
}
export default myshopDetailView
