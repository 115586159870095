import { createApp } from 'vue'
import App from './App.vue'
import store from "./store"
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
//fort-awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import LoadScript from "vue-plugin-load-script";
import globalMethods from '@/components/common/common'
import mitt from 'mitt';
import { vLongpress } from '@nanogiants/vue3-longpress';
import MasonryWall from '@yeger/vue-masonry-wall'
import 'bottom-sheet-vue3/css/style.css'
import VueAgile from 'vue-agile'
import 'font-awesome-animation/css/font-awesome-animation.min.css'

// main.js
import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';



import { createI18n } from 'vue-i18n'

import messages from "./lang"

const i18n = createI18n({
    legacy: false,
    locale: 'ko',
    fallbackLocale: 'ko',
    messages : messages
})

const $t = i18n.global.t

/*
var eventBus = new Vue()
Vue.prototype.$event = eventBus
Vue.prototype.$store = store
Vue.config_bkk.productionTip = false*/

library.add(fas, far, fal)

String.prototype.price2 = function(txt){
    var n = (this + '');

    var reg = /\B(?=(\d{3})+(?!\d))/g;
    n = n.replace(reg, ",");

    return n
}
String.prototype.price = function(txt){
    var n = (this + '');

    var reg = /\B(?=(\d{3})+(?!\d))/g;
    n = n.replace(reg, ",");

    if(txt){
        n+= $t('currency')+' ' + txt
    } else {
        n += '<span style="font-size: 0.7em"> '+$t('currency')+'</span>'
    }

    return n
}
Number.prototype.price = function(){
    var n = (this + '');

    var reg = /\B(?=(\d{3})+(?!\d))/g;
    n = n.replace(reg, ",");
    return n
}
const emitter = mitt()
import { Plugin } from 'vue-responsive-video-background-player'
import lineClamp from 'vue-line-clamp'
const app = createApp(App)

app.use(VueSweetalert2);

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(lineClamp)
app.directive('use-longpress', vLongpress);
app.use(globalMethods)
app.use(LoadScript)
app.use(VueAxios, axios)
app.use(MasonryWall)
app.use(store)
app.use(router)
app.use(VueAgile)
app.use(Plugin)
app.use(i18n)

import VueSidePanel from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css'
app.use(VueSidePanel)

import Toaster from '@meforma/vue-toaster';
app.use(Toaster)

//import axios from 'axios'

axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
    // Do something with response error
    /*
    if (error.response.status === 401) {
        console.log('unauthorized, logging out ...');
        auth.logout();
        router.replace('/auth/login');
    }
    */
    return Promise.reject(error.response);
});

app.config.globalProperties.$validateTouchStart = function () {
    var ts = this.$store.state.lastTouchStart
    //console.log(ts)
    var now = new Date().getTime()
    //console.log(now)

    ts = now - ts
    //console.log(ts)
    if(ts > 200) {
        this.$store.commit('lastTouchStart',{value:now})
        return true
    }

    return false
}

app.config.globalProperties.$http = axios
app.config.globalProperties.$cookie = {

    delete_cookie:function(name, path, domain) {
      var v = this.getCookie2(name)
  
      if( !!v ) {
        document.cookie = name + "=" +
          ((path) ? ";path="+path:"")+
          ((domain)?";domain="+domain:"") +
          ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }
    },
    setCookie365:function(name, value) {
        this.setCookie2(name, value, 60*24*365)
    }, 
    setCookie3:function(name, value) {
        this.setCookie2(name, value, 60*24*7)
    },    
    setCookie2:function(name, value, mins) {
      if (mins) {
        var date = new Date();
        date.setTime(date.getTime() + (mins * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
      } else {
        var expires = "";
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    },
    getCookie2:function(name) {
      var i, x, y, ARRcookies = document.cookie.split(";");
      for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x == name) {
          return unescape(y);
        }
      }
    },
  }
  

app.config.globalProperties.emitter = emitter
app.mount('#app')

app.component('GDialog', GDialog)

