<template>
  <details ref="details" v-if="_items">
    <summary
        :class="getMainStyle"
        :style="true ? getSummaryStyle + ';font-size:' + (default_font_size * 1) + 'em;' : getSummaryStyle + ';font-size:' + (default_font_size * 1.0) + 'em;border:red 2px solid'"
    >
      <div v-html="title" style="font-weight: bold; margin-bottom: 10px; font-size: 1.2em"></div>

      <div v-if="min_selection == 1 && max_selection == 1"></div>
      <div v-else-if="min_selection == 0 && max_selection == 1"></div>
      <div
          :style="mandatory ? 'height:40px;color:#fff;padding:0 0em;display: flex;justify-content: center;border-radius:0;font-weight:bold' : 'height:40px;color:#ff1b81;padding:0 0em;display: flex;justify-content: center;border-radius:0;font-weight:bold'"
          v-else
      >
        <div style="font-size:0.8em;align-self:center;'">
          <b :style="computed_selection">{{ $t('select') }} {{ selected_multi.length }}</b>
        </div>
        <div style="flex-grow:1"></div>
        <div style="font-size:0.8em;align-self:center;">
          {{ $t('min') }} {{ min_selection }} / {{ $t('max') }} {{ max_selection }}
        </div>
      </div>

      <ul style="margin:0">
        <template
            v-if="mandatory && max_selection == 1"
        >
          <li
              v-for="item in _items"
              :key="item.id"
              style="margin:0px"
              @click="select_item(item, 1)"
          >
            <div style="display: flex; width: 100%">
              <div style="align-self:center;min-width: 19px;margin-right:10px;background-color: white;width: 19px;height:19px;border-radius: 50%;overflow:hidden;padding:4px;">
                <div
                    v-if="mandatory_value == item.id"
                    style="background-color:#FFA500;width:100%;height:100%;border-radius: 50%;overflow:hidden"
                ></div>
              </div>
              <div
                  :style="shop_uid ? 'font-size:' + (default_font_size * 1) + 'em;display:flex;flex-grow:1;justify-content:center' : 'font-size:' + (default_font_size * 0.8) + 'em;display:flex;flex-grow:1;justify-content:center'"
              >
              <span
                  :style="item.sold_out ? 'align-self:center;text-decoration:line-through;' : 'align-self:center;'"
                  v-text="getTextValue(item)"
              ></span>
                <div style="align-self: center" v-if="item.sold_out">
                  &nbsp;({{ $t('sold_out') }})
                </div>
                <div style="flex-grow: 1"></div>
                <div
                    v-if="item.price"
                    style="align-self: center; font-weight: bold; margin-left: 10px; color: yellow"
                >
                  <span style="font-size: 1em">+</span>{{ numberWithCommas(item.price) }}
                </div>
              </div>
            </div>
          </li>
        </template>
        <template
            v-else
        >

          <li
              v-for="(item, idx) in _items"
              :key="item.id"
              style="margin:0px"
              @click.stop="clicked_checkbox2(idx, 1)"
          >
            <div
                :style="shop_uid ? 'font-size:' + (default_font_size * 1) + 'em;;display:flex;justify-content:center' : 'font-size:' + (default_font_size * 0.8) + 'em;;display:flex;justify-content:center'"
            >
              <div v-if="!item.id" v-text="title"></div>
              <div style="display: flex">
                <input
                    type="checkbox"
                    v-model="item.selected"
                    :value="true"
                    :disabled="item.sold_out"
                    style="border:2px white solid;min-width:30px;min-height: 30px;justify-content:center;align-self:center;margin-right: 10px;"
                    @change="clicked_checkbox(idx)"
                >
                <div style="margin-right: 10px; display: flex">
                <span
                    :style="item.sold_out ? 'align-self:center;text-decoration:line-through' : 'align-self:center;'"
                >
                  {{ getTextValue(item) }}
                </span>
                  <span
                      style="align-self:center;"
                      v-if="item.sold_out"
                  >
                  &nbsp;({{ $t('sold_out') }})
                </span>
                </div>
              </div>
              <div style="flex-grow: 1"></div>
              <div
                  v-if="item.price"
                  :style="mandatory ? 'align-self:center;font-weight: bold;margin-left: 10px;color:#FFA500' : 'font-weight: bold;margin-left: 10px;color:rgb(77, 55, 80);align-self:center;'"
              >
                <span style="font-size: 1em">+</span>{{ numberWithCommas(item.price) }}
              </div>
            </div>
          </li>
        </template>
      </ul>
    </summary>
  </details>

  <div v-if="!validation" style="text-align: right; padding-top: 12px; font-size: 1.2em; color:rgb(77, 55, 80); font-weight: bold;">
    * {{ title }} : {{ $t('please_select', { min: min_selection }) }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ref } from "vue";

export default {
  props: {
    warning: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '옵션'
    },
    mtype: {
      type: Number,
      default: 0
    },
    mandatory: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max_selection: {
      type: Number,
      default: 0
    },
    min_selection: {
      type: Number,
      default: 0
    },
    modelValue: Object
  },
  setup() {
    const details = ref(null);
    return { details };
  },
  computed: {
    computed_selection() {
      if (this.min_selection <= this.selected_multi.length) {
        return '';
      } else if (this.mandatory) {
        return 'color:#FFA500';
      }
    },
    getMainStyle() {
      if (this.disabled) return 'mdisabled';
      else if (this.mandatory) return 'ani';
      return '';
    },
    getSummaryStyle() {
      if (this.mandatory) return 'background-color:#ff1b81;color:#fff;font-weight:normal';
      else if (!this.mandatory) {
        return 'color:#777;font-weight:normal;';
      } else if (this.disabled) {
        return 'color:#777;font-weight:normal;border:#ff1b81 2px solid;';
      } else {
        return 'background-color:#ff1b81;color:#fff;font-weight:normal';
      }
    },
    ...mapGetters({
      admin_pos: 'admin_pos',
      shop_uid: 'shop_uid',
      default_font_size: 'default_font_size',
    }),
    getPlaceholderStatus() {
      if (this.isValid() == 0) return true;
      else if (this.isValid() == -1) {
        if (this.my_value != undefined && this.my_value != null && this.my_value != '') return false;
        return true;
      } else return false;
    },
    getStatus() {
      if (this.isValid() == -1) return 'status2 disabled';
      if (this.isValid() == 1) return 'status2 on';
      if (this.isValid() == 0) return 'status2';
    }
  },
  watch: {
    show() {
      this.init_items();
    },
    items() {
      this.init_items();
    },
    min_selection() {
      this.init_selection();
    },
    max_selection() {
      this.init_selection();
    },
    modelValue() {
      this.my_value = this.modelValue;
      this.find_selected_item();
    },
    my_value() {
      this.$emit('update:modelValue', this.my_value);
      this.$emit('change', this.my_value);
    }
  },
  data: () => ({
    validation: true,
    mandatory_value: null,
    _items: [],
    selected_multi: [],
    selected_title: '',
    my_value: null,
    //selection_mode: 'normal',
    selection_mode: 'checkbox',
    show: false
  }),
  mounted() {
    if(this.items) {
      this.init_items()
    }
  },
  methods: {
    getTextValue:function (item) {
      let name = item.alt_name?item.alt_name:item.name
      return item.id ? name : this.title
    },
    init_items() {

      if (this.items) this._items = JSON.parse(JSON.stringify(this.items));
      if (this.selected_multi) {
        this._items.forEach(el => {
          if (this.selected_multi.includes(el.id)) {
            el.selected = true;
          } else {
            el.selected = false;
          }
        });
      }
    },
    option_name(item) {
      let name = '';
      this._items.forEach(el => {
        if (el.id == item) name = el.name;
      });
      return name;
    },
    selected_cancel() {
      this.my_value = null;
      this.selected_multi = [];
    },
    check_condition() {
      if (this.min_selection <= this.selected_multi.length) {
        if (this.selected_multi) {
          this.my_value = this.selected_multi;
        } else {
          this.my_value = null;
        }
        return true;
      } else {
        this.my_value = null;
        return false;
      }
    },
    check_done() {
      if (this.min_selection <= this.selected_multi.length) {
        this.confirmed();
      } else {
        this.$swal({
          title: this.$t('please_select', { min: this.min_selection }),
          icon: 'error',
          allowOutsideClick: false,
          confirmButtonText: this.$t('confirm')
        });
        return;
      }
    },
    checked_checkbox() {
      this.check_selection();
      var selected_cnt = 0;
      this._items.forEach(el => {
        if (el.selected) {
          selected_cnt++;
        }
      });
      if (selected_cnt > 0) {
        if (this.check_condition()) {
          this.validation = true;
        } else {
          this.validation = false;
        }
      } else {
        this.validation = true;
        this.my_value = null;
      }
    },
    check_selection() {
      var current_selection = 0;
      this._items.forEach(el => {
        if (el.selected) {
          current_selection++;
        }
      });
      var len = current_selection;
      if (this.max_selection < len) {
        this._items.forEach(el => {
          if (el.selected) {
            if (this.selected_multi.includes(el.id)) {
            } else {
              el.selected = false;
            }
          }
        });
        return;
      }
      this.selected_multi = [];
      this._items.forEach(el => {
        if (el.selected) this.selected_multi.push(el.id);
      });
    },
    clicked_checkbox2(idx, ty) {
      if (this._items[idx].sold_out) return;
      this._items[idx].selected = !this._items[idx].selected;
      this.check_selection();
      var selected_cnt = 0;
      this._items.forEach(el => {
        if (el.selected) {
          selected_cnt++;
        }
      });
      if (selected_cnt > 0) {
        if (this.check_condition()) {
          this.validation = true;
        } else {
          this.validation = false;
        }
      } else {
        this.validation = true;
        this.my_value = null;
      }
    },
    clicked_checkbox(idx) {
      this.check_selection();
      var selected_cnt = 0;
      this._items.forEach(el => {
        if (el.selected) {
          selected_cnt++;
        }
      });
      if (selected_cnt > 0) {
        if (this.check_condition()) {
          this.validation = true;
        } else {
          this.validation = false;
        }
      } else {
        this.validation = true;
        this.my_value = null;
      }
    },
    init_selection() {
      if (this.mandatory && this.min_selection == this.max_selection && this.max_selection == 1) this.selection_mode = 'normal';
      else this.selection_mode = 'checkbox';
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clk_menu() {
      if (this.disabled) return;
      this.show = true;
    },
    find_selected_item() {
      if (!this.items) return;
      this.items.forEach(el => {
        if (el.id == this.my_value) {
          this.selected_title = el.name;
        }
      });
    },
    select_item(item, ty) {
      if (item.sold_out) return;
      this.mandatory_value = item.id;
      this.selected_multi = [];
      this.selected_multi.push(item.id);
      if (this.check_condition()) {
        this.validation = true;
      } else {
        this.validation = false;
      }
    },
    confirmed() {
      if (this.selected_multi) {
        this.my_value = this.selected_multi;
      } else {
        this.my_value = null;
      }
    },
    clk(item) {
      this.my_value = item.id;
    },
    isValid() {
      var stat = 0;
      if (this.disabled) {
        return -1;
      } else if (this.my_value != undefined && this.my_value != null && this.my_value != '') {
        return 1;
      }
      return stat;
    }
  }
};
</script>

<style>
.swal2-container {
  z-index: 100000002;
}
.bottom_panel_alert {
  z-index: 100000000;
}
.top_panel_alert {
  position: fixed;left: 0;bottom:0;width: 100%;
  z-index: 100000000;
}
.vue-neat-modal {
  max-height: 80% !important;
}
.vue-neat-modal-backdrop {
  z-index: 99999998;
  background-color: #ffffff99 !important;
}
.vue-neat-modal-wrapper {
  z-index: 99999999;
}
.vue-neat-modal::-webkit-scrollbar {
  width: 5px;
}
.vue-neat-modal::-webkit-scrollbar-thumb {
  background-color: #00000088;
  border-radius: 100px;
}
</style>

<style scoped>
.chip-option {
  background-color: #333;
  color: white;
  border-radius: 7px;
  padding: 5px 12px;
  margin: 6px;
}
.btn_cancel {
  border-radius: 12px;
  background-color: white;
  color: #333;
  border: #333 1px solid;
  font-weight: bold;
  padding: 10px;
}
.btn_done {
  border-radius: 12px;
  background-color: rgb(77, 55, 80);
  color: white;
  font-weight: bold;
  padding: 10px;
}
.status2 {
  margin: -2px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: rgb(252, 196, 23);
  position: absolute;
  left: 0;
  top: 0;
}

.status2.on {
  background-color: rgb(80, 164, 125);
}

.status2.disabled {
  background-color: #ddd;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.wrapper .tabs_wrap {
  border-bottom: 1px solid #ebedec;
}

.wrapper .tabs_wrap ul {
  display: flex;
  justify-content: center;
}

.wrapper .tabs_wrap ul li {
  text-align: center;
  background: #fff;
  border-right: 1px solid #c1c4c9;
  padding: 25px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.wrapper .tabs_wrap ul li:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.wrapper .tabs_wrap ul li:last-child {
  border-right: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.wrapper .tabs_wrap ul li.active,
.wrapper .tabs_wrap ul li:hover {
  background: #6d4b4b;
  color: #fff;
}

.select_border {
  color: #020202;
  font-weight: bold;
  padding: 5px;
  border-radius: 15px;
}

.option select {
  background-color: #f0f0f0;
  color: #020202;
  padding: 5px;
  font-weight: bold;
}

.placeholder {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px), calc(100% - 15px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

details {
  position: relative;
  width: 100%;
}

summary {
  padding: 1em;
  cursor: pointer;
  border-radius: 15px;
  font-weight: bold;
  background-color: #f0f0f0;
  list-style: none;
  -webkit-tap-highlight-color: transparent;
}

summary::-webkit-details-marker {
  display: none;
}

summary:focus {
  outline: none;
}

ul {
  width: 100%;
  left: 0;
  padding: 1rem;
  box-sizing: border-box;
}

li {
  margin: 0;
  padding: 1em 0;
  border-bottom: 1px solid #ccc;
}

li:last-child {
  border-bottom: 0;
}

ul.list {
  counter-reset: labels;
  border: 1px solid rgb(77, 55, 80);
}

ul.list:last-child {
  border-bottom: 0;
}

label {
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

label span {
  --display: none;
  display: var(--display);
  width: 1rem;
  height: 1rem;
  border: 1px solid #727272;
  border-radius: 3px;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type=checkbox],
  input[type=radio] {
    --active: #FFA500;
    --active-inner: #fff;
    --focus: 2px #FFA500;
    --border: #BBC1E1;
    --border-hover: #333333;
    --background: #fff;
    --disabled: #efefef;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 23px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type=checkbox]:after,
  input[type=radio]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }
  input[type=checkbox]:checked,
  input[type=radio]:checked {
    --b: #FFA500;
    --bc: #FFA500;
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  input[type=checkbox]:disabled,
  input[type=radio]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type=checkbox]:disabled:checked,
  input[type=radio]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type=checkbox]:disabled + label,
  input[type=radio]:disabled + label {
    cursor: not-allowed;
  }
  input[type=checkbox]:hover:not(:checked):not(:disabled),
  input[type=radio]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type=checkbox]:focus,
  input[type=radio]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type=checkbox]:not(.switch),
  input[type=radio]:not(.switch) {
    width: 23px;
  }
  input[type=checkbox]:not(.switch):after,
  input[type=radio]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type=checkbox]:not(.switch):checked,
  input[type=radio]:not(.switch):checked {
    --o: 1;
  }
  input[type=checkbox] + label,
  input[type=radio] + label {
    font-size: 14px;
    line-height: 23px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }
  input[type=checkbox]:not(.switch) {
    border-radius: 7px;
  }
  input[type=checkbox]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 10px;
    top: 7px;
    transform: rotate(var(--r, 20deg));
  }
  input[type=checkbox]:not(.switch):checked {
    --r: 43deg;
  }
  input[type=checkbox].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type=checkbox].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type=checkbox].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type=checkbox].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }
  input[type=radio] {
    border-radius: 50%;
  }
  input[type=radio]:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  input[type=radio]:checked {
    --s: .5;
  }
}
</style>
