<template>
  <div v-if="show" class="dialog">
    <div class="rounded_card" style="width:80%;max-width:600px;position: relative">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 20px;">
        <div v-if="!check" class="dialog_content" v-html="message" :style="'font-size:'+(default_font_size*1.2)+'em; padding-top: 1.5em !important'">
        </div>
        <div v-else class="dialog_content" v-html="message" :style="'font-size:'+(default_font_size*1.2)+'em; padding-top: 1.5em !important; color: #d8392b;'">
        </div>
        <div class="d-flex" style="width: 100%;padding-top:40px;padding-right: 20px;padding-left: 20px;">
          <div
              :style="'font-size:'+(default_font_size*1.2)+'em'"
              class="btn_next" @touchend="nextProcess">직원 확인 완료</div>
        </div>
      </div>
      <div @click.stop="forceClose" style="position: absolute;top: 15px;right: 15px;"><font-awesome-icon icon="fa-solid fa-xmark" size="2x" /></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from 'axios'

export default {
  name: "DisClosedDialog",
  computed: mapGetters({
    default_font_size: 'default_font_size',
    cart_items: 'cart_items',
    pay_amount: 'pay_amount',
    kiosk_host: 'kiosk_host',
    require_id_status: 'require_id_status',
  }),
  watch: {
    require_id_status: {
      handler() {
        if(this.require_id_status == 'server_error') {
          this.order_id = null
          this.require_failed()
        } else {
          this.order_id = this.require_id_status
        }
      },
      deep: true
    }
  },
  mounted() {
    // 이벤트 등록
    this.emitter.on('require_id', this.stop_pay)
    this.emitter.on('approve_id', this.approval)
    this.emitter.on('refuse_id', this.refusal)
    this.emitter.on('check_id', this.checkId)
  },
  data: () => ({
    show: false,
    order_id: null,
    message: '',
    check: false,
  }),
  methods: {
    stop_pay() {
      this.message = '장바구니에 성인 확인이 필요한<br>상품이 담겨 있습니다.<br><br>\n' +
          '          직원이 확인 후 <br>결제를 계속하실 수 있습니다.<br>\n' +
          '          잠시만 기다려주세요.'
      var pay_detail = {items: this.cart_items, amount: this.pay_amount}
      this.$app_event("require_id", pay_detail)
      this.show = true
    },
    require_failed() {
      this.show = false
      this.$store.commit('disable_scan', {value: false})
    },
    forceClose() {
      this.order_id = null
      this.check = false
      this.show = false
      this.$store.commit('disable_scan', {value: false})
    },
    close() {
      this.check = false
      this.show = false
      this.$store.commit('disable_scan', {value: false})
    },
    approval(ord_id) {
      if(ord_id ==  this.order_id) {
        this.close()
        this.emitter.emit("continue_pay")
      }
    },
    refusal(ord_id) {
      if(ord_id ==  this.order_id) {
        this.$app_event("audio", "refuse_id")
        location.href = "index.html"
      }
    },
    checkId(obj) {
      if(obj.order_id ==  this.order_id) {
        this.check = true
        this.message = obj.notice_text
        this.$app_event("audio", "notice")
      }
    },
    nextProcess() {
      // api 확인
      if(this.order_id == null)
        return

      var that = this
      axios.get(
          this.kiosk_host + '/kiosk/id_verification?order_id=' + this.order_id,
          {
            headers: {
              KIOSK_ID: window.session_info.pos_num,
              KIOSK_PW: window.session_info.license,
            }
          }
      ).then(res => {
        var status = res.data.verification_info.verification_status
        print(status)
        if(status == 'SUCCESS') {
          that.close()
          that.emitter.emit("continue_pay")
        }else if(status == 'FAILED'){
          that.$app_event("audio", "refuse_id")
          location.href = "index.html"
        }else{
          this.$toast.error(`아직 직원이 확인중입니다.<br>잠시만 더 기다려주세요.`,
              {
                position:
                    "top"
              }
          );
        }
      }).catch(error => {
        console.log(error)
      });
    },
  }
}
</script>

<style scoped>
</style>