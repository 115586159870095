import {createStore} from 'vuex'

export default createStore({
    state: {

        my_env:function (type) {
                try {
                    if(type == 'kiosk') {
                        if(process.env.NODE_ENV == 'beta') {
                            return 'kiosk.myshop-dev.in'
                        } else {
                            return 'myshop.do'
                        }
                    }
                    else if(type == 'smartorder') {
                        if(process.env.NODE_ENV == 'beta') {
                            return 'smartorder.myshop-dev.in'
                        } else {
                            return 'myshop-order.com'
                        }
                    }
                } catch (e) {

                }
        },

        kiosk_mode:false,
        review_detail:null,

        force_valid:false,
        lang_default_code:'ko',
        lang_current_code:'ko',
        lang_codes:null,
        sale_code:null,

        hide_footer:false,
        modify_mode:false,
        kiosk_name:null,
        kiosk_pos_mode:null,
        agent_pos:null,
        admin_table_no:null,
        tablet_auth:null,
        t_sid:0,
        hide_info:false,
        torder_status:false,
        order_details:null,
        lastTouchStart:0,
        earn_point:false,
        torder_error:false,
        delivery_info:null,
        prepay: false,
        torder_no:false,
        torder_type:'phone',
        torder_pass:'',
        torder_phone:'',
        menu_page:false,
        simple_menu:false,
        wheelDelta:null,
        flutterWindows:false,
        posApp:false,
        smart_checkout:false,
        admin_pos:false,
        admin_table:false,
        prod_restriction:false,
        touchable:false,
        default_font_size:1.2,
        banner_ratio:'60%',
        banner_ratio_right:'40%',
        vertical:true,
        tablet_menu_vertical:false,
        products: null,
        prod_options: null,
        option_mappings: null,
        scanned_barcode: null,
        cart_items: null,
        copy_items: null,
        copy_pay_type: null,
        order_items: [],
        scanned_item: null,
        require_id_status: null,
        pay_amount: null,
        pay_type: null,
        pay_data: null,
        point_num: null,
        receipt_num:null,
        receipts_type: null,
        current_point_amount: 0,
        total_point_amount: 0,
        offline_info: null,
        maininfo: null,
        selected_product: null,
        disable_scan: false,
        scanning: false,
        receipt_memo: '',
        need_installment: false,
        cart_order_id: null,
        myshop_host: null,
        kiosk_host: null,
        parsing_ok: false,
        tip: 0,
        pos_cash: false,

        pos_mode: null,
        perItem:3,
        selected_top_category:0,

        product_loaded:false,
        shop_uid: null,
        shop_info:null,
        category_data:null,

        login: null,
        sid:null,
        skey:null,
        kid:null,
        kpw:null,


        member_sale:null,
        sale_available:null,
        review_available:null,

        myzoom:100,
        ad_visible:false,

        //noimg:'https://cdnpxy0.mocoplex.com/resize_w/512/http://storage0.mocoplex.com/kiosk/drink2.png',
        noimg: require('@/assets/images/no_img.png'),
        //noimg:'/images/noimg.png',
        tablet_main_top_height: 0,
    },
    getters: {
        force_valid(state) {
            return state.force_valid
        },
        tablet_menu_vertical(state) {
            return state.tablet_menu_vertical
        },
        review_detail(state) {
            return state.review_detail
        },
        kiosk_mode(state) {
            return state.kiosk_mode
        },
        sale_code(state) {
            return state.sale_code
        },
        lang_codes(state) {
            return state.lang_codes
        },
        lang_current_code(state) {
            return state.lang_current_code
        },
        lang_default_code(state) {
            return state.lang_default_code
        },
        review_available(state) {
            return state.review_available
        },
        sale_available(state) {
            return state.sale_available
        },
        member_sale(state) {
            return state.member_sale
        },

        myzoom(state) {
            return state.myzoom
        },

        hide_footer(state) {
            return state.hide_footer
        },
        modify_mode(state) {
            return state.modify_mode
        },
        kiosk_name(state) {
            return state.kiosk_name
        },
        kiosk_pos_mode(state) {
            return state.kiosk_pos_mode
        },
        agent_pos(state) {
            return state.agent_pos
        },
        admin_table_no(state) {
            return state.admin_table_no
        },
        tablet_auth(state) {
            return state.tablet_auth
        },
        hide_info(state) {
            return state.hide_info
        },
        t_sid(state) {
            return state.t_sid
        },
        torder_status(state) {
            return state.torder_status
        },
        order_details(state) {
            return state.order_details
        },
        lastTouchStart(state) {
            return state.lastTouchStart
        },
        earn_point(state) {
            return state.earn_point
        },
        torder_error(state) {
            return state.torder_error
        },
        delivery_info(state) {
            return state.delivery_info
        },
        prepay(state) {
            return state.prepay
        },
        torder_no(state) {
            return state.torder_no
        },
        torder_type(state) {
            return state.torder_type
        },
        torder_pass(state) {
            return state.torder_pass
        },
        torder_phone(state) {
            return state.torder_phone
        },
        menu_page(state) {
            return state.menu_page
        },
        simple_menu(state) {
            return state.simple_menu
        },
        wheelDelta(state) {
            return state.wheelDelta
        },
        flutterWindows(state) {
            return state.flutterWindows
        },
        posApp(state) {
            return state.posApp
        },
        smart_checkout(state) {
            return state.smart_checkout
        },
        kid(state) {
            return state.kid
        },
        kpw(state) {
            return state.kpw
        },
        admin_table(state) {
            return state.admin_table
        },
        admin_pos(state) {
            return state.admin_pos
        },
        login(state) {
            return state.login
        },
        sid(state) {
            return state.sid
        },
        skey(state) {
            return state.skey
        },


        category_data(state) {
            return state.category_data
        },

        selected_top_category(state) {
            return state.selected_top_category
        },
        prod_restriction(state) {
            return state.prod_restriction
        },
        touchable(state) {
            return state.touchable
        },
        shop_info(state) {
            return state.shop_info
        },
        noimg(state) {
            return state.noimg
        },
        product_loaded(state) {
            return state.product_loaded
        },
        perItem(state) {
            return state.perItem
        },
        shop_uid(state) {
            return state.shop_uid
        },
        banner_ratio_right(state) {
            return state.banner_ratio_right
        },
        default_font_size(state) {
            return state.default_font_size
        },
        banner_ratio(state) {
            return state.banner_ratio
        },
        vertical(state) {
            return state.vertical
        },
        products(state) {
            return state.products
        },
        prod_options(state) {
            return state.prod_options
        },
        option_mappings(state) {
            return state.option_mappings
        },
        scanned_barcode(state) {
            return state.scanned_barcode
        },
        cart_items(state) {
            return state.cart_items
        },
        copy_items(state) {
            return state.copy_items
        },
        copy_pay_type(state) {
            return state.copy_pay_type
        },
        order_items(state) {
            return state.order_items
        },
        scanned_item(state) {
            return state.scanned_item
        },
        require_id_status(state) {
            return state.require_id_status
        },
        pay_amount(state) {
            return state.pay_amount
        },
        pay_type(state) {
            return state.pay_type
        },
        pay_data(state) {
            return state.pay_data
        },
        point_num(state) {
            return state.point_num
        },
        receipt_num(state) {
            return state.receipt_num
        },
        receipts_type(state) {
            return state.receipts_type
        },
        current_point_amount(state) {
            return state.current_point_amount
        },
        total_point_amount(state) {
            return state.total_point_amount
        },
        offline_info(state) {
            return state.offline_info
        },
        maininfo(state) {
            return state.maininfo
        },
        selected_product(state) {
            return state.selected_product
        },
        disable_scan(state) {
            return state.disable_scan
        },
        scanning(state) {
            return state.scanning
        },
        receipt_memo(state) {
            return state.receipt_memo
        },
        need_installment(state) {
            return state.need_installment
        },
        cart_order_id(state) {
            return state.cart_order_id
        },
        myshop_host(state) {
            return state.myshop_host
        },
        kiosk_host(state) {
            return state.kiosk_host
        },
        parsing_ok(state) {
            return state.parsing_ok
        },
        tip(state) {
            return state.tip
        },
        pos_cash(state) {
            return state.pos_cash
        },
        pos_mode(state) {
            return state.pos_mode
        },
        ad_visible(state) {
            return state.ad_visible
        },
        tablet_main_top_height(state) {
            return state.tablet_main_top_height
        },
    },
    mutations: {
        force_valid (state, {value}) {
            state.force_valid = value
        },
        tablet_menu_vertical (state, {value}) {
            state.tablet_menu_vertical = value
        },
        review_detail (state, {value}) {
            state.review_detail = value
        },
        kiosk_mode (state, {value}) {
            state.kiosk_mode = value
        },
        sale_code (state, {value}) {
            state.sale_code = value
        },
        lang_codes (state, {value}) {
            state.lang_codes = value
        },
        lang_current_code (state, {value}) {
            state.lang_current_code = value
        },
        lang_default_code (state, {value}) {
            state.lang_default_code = value
        },
        ad_visible (state, {value}) {
            state.ad_visible = value
        },
        review_available (state, {value}) {
            state.review_available = value
        },
        sale_available (state, {value}) {
            state.sale_available = value
        },
        member_sale (state, {value}) {
            state.member_sale = value
        },
        myzoom (state, {value}) {
            state.myzoom = value
        },
        hide_footer (state, {value}) {
            state.hide_footer = value
        },
        modify_mode (state, {value}) {
            state.modify_mode = value
        },
        kiosk_name (state, {value}) {
            state.kiosk_name = value
        },
        kiosk_pos_mode (state, {value}) {
            state.kiosk_pos_mode = value
        },
        admin_table_no (state, {value}) {
            state.admin_table_no = value
        },
        agent_pos (state, {value}) {
            state.agent_pos = value
        },
        tablet_auth (state, {value}) {
            state.tablet_auth = value
        },
        hide_info (state, {value}) {
            state.hide_info = value
        },        
        t_sid (state, {value}) {
            state.t_sid = value
        },
        torder_status (state, {value}) {
            state.torder_status = value
        },
        order_details (state, {value}) {
            state.order_details = value
        },
        lastTouchStart (state, {value}) {
            state.lastTouchStart = value
        },
        earn_point (state, {value}) {
            state.earn_point = value
        },
        torder_error (state, {value}) {
            state.torder_error = value
        },
        admin_table (state, {value}) {
            state.admin_table = value
        },
        delivery_info (state, {value}) {
            state.delivery_info = value
        },
        prepay (state, {value}) {
            state.prepay = value
        },
        torder_no (state, {value}) {
            state.torder_no = value
        },
        torder_phone (state, {value}) {
            state.torder_phone = value
        },
        torder_type (state, {value}) {
            state.torder_type = value
        },
        torder_pass (state, {value}) {
            state.torder_pass = value
        },
        menu_page (state, {value}) {
            state.menu_page = value
        },
        simple_menu (state, {value}) {
            state.simple_menu = value
        },
        wheelDelta (state, {value}) {
            state.wheelDelta = value
        },

        flutterWindows (state, {value}) {
            state.flutterWindows = value
        },
        posApp (state, {value}) {
            state.posApp = value
        },
        smart_checkout (state, {value}) {
            state.smart_checkout = value
        },
        kid (state, {value}) {
            state.kid = value
        },
        kpw (state, {value}) {
            state.kpw = value
        },

        admin_pos (state, {value}) {
            state.admin_pos = value
        },
        login (state, {value}) {
            state.login = value
        },
        sid (state, {value}) {
            state.sid = value
        },
        skey (state, {value}) {
            state.skey = value
        },

        category_data (state, {value}) {
            state.category_data = value
        },
        selected_top_category (state, {value}) {
            state.selected_top_category = value
        },
        prod_restriction (state, {value}) {
            state.prod_restriction = value
        },
        touchable (state, {value}) {
            state.touchable = value
        },
        shop_info (state, {value}) {
            state.shop_info = value
        },
        product_loaded (state, {value}) {
            state.product_loaded = value
        },
        perItem (state, {value}) {
            state.perItem = value
        },
        shop_uid (state, {value}) {
            state.shop_uid = value
        },
        default_font_size (state, {value}) {
            state.default_font_size = value
        },
        banner_ratio_right (state, {value}) {
            state.banner_ratio_right = value
        },
        banner_ratio (state, {value}) {
            state.banner_ratio = value
        },
        vertical (state, {value}) {
            state.vertical = value
        },
        products (state, {value}) {
            state.products = value
        },
        prod_options (state, {value}) {
            state.prod_options = value
        },
        option_mappings (state, {value}) {
            state.option_mappings = value
        },
        scanned_barcode (state, {value}) {
            state.scanned_barcode = value
        },
        cart_items (state, {value}) {
            state.cart_items = value
        },
        copy_items (state, {value}) {
            state.copy_items = value
        },
        copy_pay_type (state, {value}) {
            state.copy_pay_type = value
        },
        order_items (state, {value}) {
            state.order_items = value
        },
        scanned_item (state, {value}) {
            state.scanned_item = value
        },
        require_id_status (state, {value}) {
            state.require_id_status = value
        },
        pay_amount (state, {value}) {
            state.pay_amount = value
        },
        pay_type (state, {value}) {
            state.pay_type = value
        },
        pay_data (state, {value}) {
            state.pay_data = value
        },
        point_num (state, {value}) {
            state.point_num = value
        },
        receipt_num (state, {value}) {
            state.receipt_num = value
        },
        receipts_type (state, {value}) {
            state.receipts_type = value
        },
        current_point_amount (state, {value}) {
            state.current_point_amount = value
        },
        total_point_amount (state, {value}) {
            state.total_point_amount = value
        },
        offline_info (state, {value}) {
            state.offline_info = value
        },
        maininfo (state, {value}) {
            state.maininfo = value
        },
        selected_product (state, {value}) {
            state.selected_product = value
        },
        disable_scan (state, {value}) {
            state.disable_scan = value
        },
        scanning (state, {value}) {
            state.scanning = value
        },
        receipt_memo (state, {value}) {
            state.receipt_memo = value
        },
        need_installment (state, {value}) {
            state.need_installment = value
        },
        cart_order_id (state, {value}) {
            state.cart_order_id = value
        },
        myshop_host (state, {value}) {
            state.myshop_host = value
        },
        kiosk_host (state, {value}) {
            state.kiosk_host = value
        },
        parsing_ok (state, {value}) {
            state.parsing_ok = value
        },
        tip (state, {value}) {
            state.tip = value
        },
        pos_cash (state, {value}) {
            state.pos_cash = value
        },
        pos_mode (state, {value}) {
            state.pos_mode = value
        },
        tablet_main_top_height (state, {value}) {
            state.tablet_main_top_height = value
        },
    },
});
